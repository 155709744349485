import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ExitToApp } from '@material-ui/icons';
import { auth } from '../firebase';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  grow: {
    flexGrow: 1
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(3 * 2))]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  }
}));

const Layout = ({ children }) => {
  const { root, grow, layout } = useStyles();
  return (
    <div className={root}>
      <AppBar position="static">
        <Toolbar>
          <Button component={RouterLink} to="/" color="inherit">
            <Typography variant="h6" color="inherit">
              PTTEP World
            </Typography>
          </Button>
          <div className={grow} />
          <Button component={RouterLink} to="/ranking" color="inherit">
            Ranking
          </Button>
          <Button component={RouterLink} to="/review" color="inherit">
            Review
          </Button>
          <Button component={RouterLink} to="/personality" color="inherit">
            Personality Result
          </Button>
          <Button component={RouterLink} to="/settings" color="inherit">
            Settings
          </Button>
          <IconButton color="inherit" onClick={() => auth.signOut()}>
            <ExitToApp />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={layout}>{children}</div>
    </div>
  );
};

export default Layout;
