import axios from 'axios';
import { auth, api } from '../firebase';
import XLSX from 'xlsx';
import moment from 'moment';

class RankingService {
  static getRank = async (game, type, limit, pageType, uid) => {
    try {
      const token = await auth.currentUser.getIdToken();
      const res = await axios({
        method: 'get',
        url: `${api}/scores/${game}`,
        headers: { Authorization: `Bearer ${token}` },
        params: {
          type,
          limit: limit ? limit : null,
          pageType: pageType,
          uid: uid
        }
      });
      return { docs: res.data.data, hasOtherPage: res.data.hasOtherPage };
    } catch (e) {
      // setMessage('เกิดข้อผิดพลาด');
      throw e;
    }
  };
  static getRankByDay = async (game, type, date, limit, pageType, uid) => {
    try {
      const token = await auth.currentUser.getIdToken();
      const res = await axios({
        method: 'get',
        url: `${api}/scores/${game}`,
        headers: { Authorization: `Bearer ${token}` },
        params: {
          type,
          limit: limit ? limit : null,
          pageType,
          uid: uid,
          date
        }
      });
      console.log(res.data);
      return { docs: res.data.data, hasOtherPage: res.data.hasOtherPage };
    } catch (e) {
      // setMessage('เกิดข้อผิดพลาด');
      throw e;
    }
  };
  static getRankByRange = async (game, type, fromDate, toDate, limit) => {
    try {
      const token = await auth.currentUser.getIdToken();
      const res = await axios({
        method: 'get',
        url: `${api}/scores/${game}`,
        headers: { Authorization: `Bearer ${token}` },
        params: {
          type,
          limit: limit ? limit : null,
          date: fromDate,
          toDate
        }
      });
      console.log(res.data);
      return { docs: res.data.data, hasOtherPage: res.data.hasOtherPage };
    } catch (e) {
      // setMessage('เกิดข้อผิดพลาด');
      throw e;
    }
  };

  static getPeriod = period => {
    switch (period) {
      case 'week':
        return `${moment()
          .startOf('week')
          .format('DDMMYYYY')}-${moment()
          .endOf('week')
          .format('DDMMYYYY')}`;
      case 'lastweek':
        return `${moment()
          .add(-1, 'week')
          .startOf('week')
          .format('DDMMYYYY')}-${moment()
          .add(-1, 'week')
          .endOf('week')
          .format('DDMMYYYY')}`;
      case 'month':
        return `${moment()
          .startOf('month')
          .format('DDMMYYYY')}-${moment()
          .endOf('month')
          .format('DDMMYYYY')}`;
      case 'lastmonth':
        return `${moment()
          .add(-1, 'month')
          .startOf('month')
          .format('DDMMYYYY')}-${moment()
          .add(-1, 'month')
          .endOf('month')
          .format('DDMMYYYY')}`;

      default:
        return period;
    }
  };

  static exportData = (game, period, docs) => {
    // convert raw data to readable
    docs = docs.map(doc => ({
      id: doc.id,
      name: doc.displayName,
      score: doc.score
    }));
    const wb = XLSX.utils.book_new();
    // add header as the first column
    docs = [{ id: 'ไอดี', name: 'ชื่อ', score: 'คะแนน' }, ...docs];
    // skip header in order to use our custom first-row header
    const ws = XLSX.utils.json_to_sheet(docs, { skipHeader: true });
    XLSX.utils.book_append_sheet(wb, ws, 'report');
    XLSX.writeFile(
      wb,
      `report-ranking-${
        game === 'world' ? 'allGame' : game
      }-${RankingService.getPeriod(period)}.xlsx`
    );
  };
}

export default RankingService;
