import React, { useState } from 'react';
import {
  FilledInput,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import Layout from '../components/Layout';
import RankingTable from '../components/RankingTable';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import rankingService from '../services/Ranking';

const useStyles = makeStyles(theme => ({
  paper: theme.paper,
  row: theme.row,
  header: {
    justifyContent: 'space-between'
  },
  formControl: {
    marginLeft: '8px'
  },
  select: {
    minWidth: '200px'
  },
  datePicker: {
    display: 'inline-flex',
    marginLeft: '8px',
    formControl: {
      color: 'yellow',
      filledInput: {
        dateFormatInput: {
          height: '100%'
        }
      }
    }
  },
  datePickerInput: {
    height: 'auto'
  }
}));

const Ranking = () => {
  const [game, setGame] = useState('world');
  const [period, setPeriod] = useState('alltime');
  const [date, setDate] = useState(moment());
  const [toDate, setToDate] = useState(moment());
  const { paper, row, header, formControl, select } = useStyles();

  const usePeriodRange = () =>
    period === 'day' && (game === 'energy' || game === 'seas');

  const onChangePeriod = event => {
    setPeriod(event.target.value);
    setDate(moment());
  };

  const handleExport = async e => {
    const { docs } = await (period === 'day'
      ? rankingService.getRankByDay(game, period, date.toDate())
      : rankingService.getRank(game, period));
    rankingService.exportData(
      game,
      period === 'day' ? date.format('DD-MM-YYYY') : period,
      docs
    );
  };
  return (
    <Layout>
      <Paper className={paper}>
        <div className={classNames(row, header)}>
          <Typography variant="h4">Ranking</Typography>
          <div>
            {period === 'day' && (
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  label="from"
                  value={date}
                  onChange={setDate}
                  inputVariant="filled"
                  variant="inline"
                  autoOk={true}
                  maxDate={moment()}
                />
              </MuiPickersUtilsProvider>
            )}
            {usePeriodRange() && (
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  label="to"
                  value={toDate}
                  onChange={setToDate}
                  inputVariant="filled"
                  variant="inline"
                  autoOk={true}
                  minDate={date}
                  maxDate={moment()}
                />
              </MuiPickersUtilsProvider>
            )}

            <FormControl className={formControl} variant="filled">
              <InputLabel htmlFor="period-select">Period</InputLabel>
              <Select
                className={select}
                value={period}
                onChange={onChangePeriod}
                input={<FilledInput id="period-select" />}
              >
                <MenuItem value={'alltime'}>All Time</MenuItem>
                <MenuItem value={'month'}>This Month</MenuItem>
                <MenuItem value={'lastmonth'}>Last Month</MenuItem>
                <MenuItem value={'week'}>This Week</MenuItem>
                <MenuItem value={'lastweek'}>Last Week</MenuItem>
                <MenuItem value={'day'}>Day</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={formControl} variant="filled">
              <InputLabel htmlFor="game-select">Game</InputLabel>
              <Select
                className={select}
                value={game}
                onChange={event => setGame(event.target.value)}
                input={<FilledInput id="game-select" />}
              >
                <MenuItem value={'world'}>All Games</MenuItem>
                <MenuItem value={'energy'}>Finding Energy</MenuItem>
                <MenuItem value={'trees'}>Save the Trees</MenuItem>
                <MenuItem value={'seas'}>Save the Seas</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div>
          {['world', 'energy', 'trees', 'seas'].map(
            current =>
              game === current && (
                <RankingTable
                  key={current}
                  game={current}
                  rankType={period}
                  date={period === 'day' ? date.toDate() : null}
                  toDate={usePeriodRange() ? toDate.toDate() : null}
                  onExport={handleExport}
                />
              )
          )}
        </div>
      </Paper>
    </Layout>
  );
};

export default Ranking;
