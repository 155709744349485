import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { Button, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import XLSX from 'xlsx';
import classNames from 'classnames';

import Layout from '../components/Layout';

import CloudService from '../services/Cloud';

const useStyles = makeStyles(theme => ({
  paper: theme.paper,
  row: theme.row,
  header: {
    justifyContent: 'space-between'
  },
  actionBar: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  exportButton: {
    margin: `${theme.spacing(1)}px 0`
  }
}));

const columns = [
  {
    Header: 'ลำดับที่',
    accessor: 'index'
  },
  {
    Header: 'ดาว',
    accessor: 'star'
  },
  {
    Header: 'คำแนะนำ',
    accessor: 'comment'
  },
  {
    Header: 'เวลา',
    accessor: 'createdAt'
  }
];

const Review = () => {
  const [items, setItems] = useState([]);
  const { actionBar, exportButton, paper, row, header } = useStyles();

  useEffect(() => {
    CloudService.fetch('reviews', 'createdAt', 'desc').then(items =>
      setItems(
        items.map((item, index) => ({
          ...item,
          index: index + 1,
          createdAt: moment(item.createdAt.toDate())
            .local()
            .format('DD-MM-YYYY HH:mm')
        }))
      )
    );
  }, []);

  const handleExport = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(items);
    XLSX.utils.book_append_sheet(wb, ws, 'report');
    XLSX.writeFile(wb, 'report.xlsx');
  };

  return (
    <Layout>
      <Paper className={paper}>
        <div className={classNames(row, header)}>
          <Typography variant="h4">Review</Typography>
        </div>
        <ReactTable
          data={items}
          columns={columns}
          style={{ textAlign: 'center' }}
        />
        <div className={actionBar}>
          <Button
            variant="contained"
            color="primary"
            className={exportButton}
            onClick={() => handleExport()}
          >
            Export
          </Button>
        </div>
      </Paper>
    </Layout>
  );
};

export default Review;
