import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Fab,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { blue } from '@material-ui/core/colors';
import { Delete, Edit } from '@material-ui/icons';
import { database } from '../firebase';

const useStyles = makeStyles(theme => ({
  dialog: {
    minWidth: '400px'
  },
  fab: {
    marginLeft: '8px'
  },
  row: theme.row,
  progressContainer: {
    display: 'flex',
    minHeight: '200px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  formItem: {
    marginBottom: '8px'
  }
}));

const TipList = () => {
  const [tips, setTips] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [editingTip, setEditingTip] = useState();
  const { fab, progressContainer, row } = useStyles();

  useEffect(() => {
    fetchTips();
  }, []);

  const fetchTips = async () => {
    let tips = [];
    const docs = await database
      .collection('tips')
      .orderBy('createdAt')
      .get();
    docs.forEach(doc => (tips = [...tips, { id: doc.id, ...doc.data() }]));
    setTips(tips);
  };

  const editTip = async tip => {
    setEditingTip(tip);
    setShowDialog(true);
  };

  const deleteTip = async tip => {
    const confirmed = window.confirm(`Delete this item: ${tip.message}?`);
    if (confirmed) {
      setTips(tips.filter(current => current.id !== tip.id));
      database
        .collection('tips')
        .doc(tip.id)
        .delete();
    }
  };

  const handleClose = async updatedTip => {
    setEditingTip(null);
    setShowDialog(false);
    if (!updatedTip) return;

    console.log(updatedTip);
    const { message, scene, createdAt } = updatedTip;
    if (updatedTip.id) {
      const newTips = tips.map(tip =>
        tip.id === updatedTip.id ? { ...tip, message, scene } : tip
      );
      setTips(newTips);

      await database
        .collection('tips')
        .doc(updatedTip.id)
        .set({ message, scene, createdAt });
    } else {
      await database
        .collection('tips')
        .add({ message, scene, createdAt: new Date() });
      fetchTips();
    }
  };

  return (
    <>
      <div className={row}>
        <Typography variant="h4">Tips</Typography>
        <Fab
          className={fab}
          size="small"
          color="secondary"
          onClick={() => editTip()}
        >
          +
        </Fab>
      </div>
      {tips.length > 0 ? (
        <List>
          {tips.map((tip, index) => (
            <ListItem key={index}>
              <ListItemAvatar>
                <TipAvatar scene={tip.scene} />
              </ListItemAvatar>
              <ListItemText primary={tip.message} />
              <ListItemSecondaryAction>
                <IconButton onClick={() => editTip(tip)}>
                  <Edit />
                </IconButton>
                <IconButton onClick={() => deleteTip(tip)}>
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      ) : (
        <div className={progressContainer}>
          <CircularProgress />
        </div>
      )}
      <TipDialog
        open={showDialog}
        handleClose={handleClose}
        editingTip={editingTip}
      />
    </>
  );
};

const TipDialog = ({ open, editingTip, handleClose }) => {
  const defaultTip = { message: '', scene: 'world' };
  const [tip, setTip] = useState(defaultTip);
  const { dialog, formItem } = useStyles();
  useEffect(() => setTip(editingTip || defaultTip), [editingTip]);

  return (
    <Dialog
      classes={{ paper: dialog }}
      open={open}
      onClose={() => handleClose()}
    >
      <DialogTitle>
        {editingTip ? 'แก้ไขเกร็ดความรู้' : 'เพิ่มเกร็ดความรู้'}
      </DialogTitle>
      <DialogContent>
        <TextField
          classes={{ root: formItem }}
          fullWidth
          label="Message"
          value={tip.message}
          onChange={e => setTip({ ...tip, message: e.target.value })}
        />
        <FormControl classes={{ root: formItem }} fullWidth>
          <InputLabel htmlFor="scene">Scene</InputLabel>
          <Select
            inputProps={{
              name: 'scene',
              id: 'scene'
            }}
            value={tip.scene}
            onChange={e => setTip({ ...tip, scene: e.target.value })}
          >
            <MenuItem value="world">World</MenuItem>
            <MenuItem value="energy">Energy</MenuItem>
            <MenuItem value="trees">Trees</MenuItem>
            <MenuItem value="seas">Seas</MenuItem>
            <MenuItem value="tour">Tour</MenuItem>
            <MenuItem value="personality">Personality</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} color="primary">
          ยกเลิก
        </Button>
        <Button onClick={() => handleClose(tip)} color="primary" autoFocus>
          ตกลง
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const TipAvatar = ({ scene }) => {
  switch (scene) {
    case 'world':
      return <Avatar style={{ backgroundColor: blue[50] }}>W</Avatar>;
    case 'energy':
      return <Avatar style={{ backgroundColor: blue[200] }}>E</Avatar>;
    case 'trees':
      return <Avatar style={{ backgroundColor: blue[400] }}>T</Avatar>;
    case 'seas':
      return <Avatar style={{ backgroundColor: blue[600] }}>S</Avatar>;
    case 'tour':
      return <Avatar style={{ backgroundColor: blue[800] }}>T</Avatar>;
    case 'personality':
      return <Avatar style={{ backgroundColor: blue[900] }}>P</Avatar>;
    default:
      return <div />;
  }
};

export default TipList;
