import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Layout from '../components/Layout';
import TipList from '../components/TipList';
import Upload from '../components/Upload';
import { api, auth } from '../firebase';

const useStyles = makeStyles(theme => ({
  paper: theme.paper,
  row: theme.row,
  col: theme.col
}));

const Settings = () => {
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackBar] = useState(false);
  const [message, setMessage] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [targetFile, setTargetFile] = useState(null);
  const [configs, setConfigs] = useState({});
  const { paper } = useStyles();

  useEffect(() => {
    fetchConfigs();
  }, []);

  const fetchConfigs = async () => {
    const token = await auth.currentUser.getIdToken();
    const res = await axios({
      method: 'get',
      url: `${api}/admin/configs`,
      headers: {
        Authorization: token
      }
    });
    console.log(res.data);
    setConfigs(res.data);
  };

  const handleUpload = async (game, file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    try {
      await axios({
        method: 'post',
        url: `${api}/upload/${game}`,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: formData
      });
      setMessage(`นำเข้าสำเร็จ`);
    } catch (e) {
      setMessage('เกิดข้อผิดพลาด');
    } finally {
      setLoading(false);
      setSnackBar(true);
    }
  };

  const handleDevUpload = async (game, file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    try {
      await axios({
        method: 'post',
        url: `${api}/upload/${game}`,
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Dev-Mode': true
        },
        data: formData
      });
      setMessage(`นำเข้าสำเร็จ`);
    } catch (e) {
      setMessage('เกิดข้อผิดพลาด');
    } finally {
      setLoading(false);
      setSnackBar(true);
    }
  };
  const handleOpenConfirmLocalization = (game, file) => {
    setTargetFile(file);
    setOpenDialog(true);
  };
  const handleUploadLocalization = async file => {
    setOpenDialog(false);
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    try {
      await axios({
        method: 'post',
        url: `${api}/localization/`,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: formData
      });
      setMessage(`นำเข้าสำเร็จ`);
    } catch (e) {
      setMessage('เกิดข้อผิดพลาด');
    } finally {
      setLoading(false);
      setSnackBar(true);
    }
  };

  const prepareTreesConfig = () => {
    const { trees } = configs;
    if (!trees) return [];
    return [
      `ระยะเวลารอปุ๋ย ${trees.gameSetting.fertilizerCooldown} วินาที`,
      `การเจริญเติบโตของต้นไม้หลังใส่ปุ๋ย ${trees.gameSetting
        .fertilizerPercent * 100}%`,
      `ระยะเวลารอศัตรูพืช ${trees.gameSetting.pestCooldown} วินาที`,
      `โอกาสในการเกิดศัตรูพืช ${trees.gameSetting.pestChance * 100}%`
    ];
  };
  const prepareSeasConfig = () => {
    const { seas } = configs;
    if (!seas) return [];
    return [
      `เวลาในการเล่นเกมต่อรอบ ${seas.Gameplay.time} วินาที`,
      `จำนวนของรางวัลพิเศษสูงสุด ${seas.GameConfig.maxRewardPerDay} ต่อวัน`
    ];
  };
  const prepareEnergyConfig = () => {
    const { energy } = configs;
    if (!energy) return [];
    return [
      `เวลาในการเล่นเกมต่อรอบ ${energy.GameConfig.GameSec} วินาที`,
      `จำนวนของรางวัลพิเศษสูงสุด ${energy.GameConfig.maxRewardPerDay} ต่อวัน`,
      `จำนวนเงินเริ่มต้น ${20000}`
    ];
  };

  return (
    <Layout>
      <Upload
        topic="Save the trees"
        loading={loading}
        snackbar={snackbar}
        setSnackBar={setSnackBar}
        message={message}
        game="trees"
        handleUpload={handleUpload}
        handleDevUpload={handleDevUpload}
        devBtn={true}
        configs={prepareTreesConfig()}
      />
      <Upload
        topic="Save the seas"
        loading={loading}
        snackbar={snackbar}
        setSnackBar={setSnackBar}
        message={message}
        game="seas"
        handleUpload={handleUpload}
        handleDevUpload={handleDevUpload}
        devBtn={true}
        configs={prepareSeasConfig()}
      />
      <Upload
        topic="Finding energy"
        loading={loading}
        snackbar={snackbar}
        setSnackBar={setSnackBar}
        message={message}
        game="energy"
        handleUpload={handleUpload}
        handleDevUpload={handleDevUpload}
        devBtn={true}
        configs={prepareEnergyConfig()}
      />
      <Upload
        topic="Localization"
        loading={loading}
        snackbar={snackbar}
        setSnackBar={setSnackBar}
        message={message}
        game="energy"
        handleUpload={handleOpenConfirmLocalization}
        devBtn={false}
      />
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Confirm upload?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            blah blah blah
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleUploadLocalization(targetFile)}
            color="primary"
            autoFocus
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
      <Paper className={paper}>
        <TipList />
      </Paper>
    </Layout>
  );
};

export default Settings;
