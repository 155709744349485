import { database } from '../firebase';

class CloudService {
  static fetch = async (collection, orderByKey, orderBy) => {
    try {
      let res;
      if (orderBy && orderByKey) {
        res = await database
          .collection(collection)
          .orderBy(orderByKey, orderBy)
          .get();
      } else {
        res = await database.collection(collection).get();
      }
      let docs = [];
      res.docs.forEach(doc => {
        docs = [...docs, { ...doc.data(), id: doc.id }];
      });
      return docs;
    } catch (error) {
      throw error;
    }
  };
}

export default CloudService;
