import React, { useEffect, useState, useContext } from 'react';
import {
  Avatar,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  CircularProgress,
  Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import AuthContext from '../context/auth';
import rankingService from '../services/Ranking';
import moment from 'moment';

const useStyles = makeStyles({
  controlPanel: {
    display: 'flex',
    padding: '16px 8px',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  controlPanelItem: {
    marginRight: '4px'
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '64px'
  }
});
const limit = 20;
const RankingTable = ({ game, rankType, date, onExport, toDate }) => {
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [hasOtherPage, setHasOtherPage] = useState(false);
  const [loading, setLoading] = useState(true);
  const { state } = useContext(AuthContext);
  const { controlPanel, controlPanelItem, progress } = useStyles();
  const getRank = (pageType, uid) => {
    if (date) {
      if (toDate && !moment(date).isSame(moment(toDate), 'day')) {
        return rankingService.getRankByRange(
          game,
          'range',
          date,
          toDate,
          limit
        );
      }
      return rankingService.getRankByDay(
        game,
        rankType,
        date,
        limit,
        pageType,
        uid
      );
    }
    return rankingService.getRank(game, rankType, limit, pageType, uid);
  };

  useEffect(() => {
    if (!state.loading) {
      setItemPerPage(10);
      getRank().then(({ docs, hasOtherPage }) => {
        setItems(docs);
        setHasOtherPage(hasOtherPage);
        setLoading(false);
      });
    }
  }, [state.loading]);
  useEffect(() => {
    if (!state.loading) {
      console.log('update rankType');
      setLoading(true);
      setItems([]);
      setPage(1);
      getRank().then(({ docs, hasOtherPage }) => {
        setItems(docs);
        setHasOtherPage(hasOtherPage);
        setLoading(false);
      });
    }
  }, [rankType]);
  useEffect(() => {
    if (!state.loading) {
      console.log('update date');
      setLoading(true);
      setItems([]);
      setPage(1);
      getRank().then(({ docs, hasOtherPage }) => {
        setItems(docs);
        setHasOtherPage(hasOtherPage);
        setLoading(false);
      });
    }
  }, [date]);
  const prevPage = () => {
    setPage(page - 1);
    setLoading(true);
    setItems([]);
    getRank('prev', items[0].id).then(({ docs, hasOtherPage }) => {
      setItems(docs);
      setHasOtherPage(true);
      setLoading(false);
    });
  };
  const nextPage = () => {
    setPage(page + 1);
    setLoading(true);
    setItems([]);
    getRank('next', items[items.length - 1].id).then(
      ({ docs, hasOtherPage }) => {
        setItems(docs);
        setHasOtherPage(hasOtherPage);
        setLoading(false);
      }
    );
  };
  return (
    <>
      <List>
        {loading ? (
          <div className={progress}>
            <CircularProgress />
          </div>
        ) : null}
        {items.map((item, index) => {
          return (
            <ListItem key={index}>
              <ListItemAvatar>
                <Avatar>
                  <img src={item.profilePicture} alt={item.displayName} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`${(page - 1) * itemPerPage + (index + 1)}. ${
                  item.displayName
                }`}
                secondary={item.score.toLocaleString()}
              />
              {/* <ListItemSecondaryAction>
                <IconButton onClick={() => openFacebook(item)}>
                  <OpenInNew />
                </IconButton>
              </ListItemSecondaryAction> */}
            </ListItem>
          );
        })}
      </List>
      <Divider />
      <div className={controlPanel}>
        {items.length > 0 && (
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: '10px' }}
            onClick={onExport}
          >
            Export
          </Button>
        )}
        {items.length === 0 ? null : (
          <Typography className={controlPanelItem}>
            {1 + (page - 1) * itemPerPage} -{' '}
            {itemPerPage * (page - 1) + items.length} of many
          </Typography>
        )}
        <IconButton
          className={controlPanelItem}
          disabled={page === 1 || loading}
          onClick={prevPage}
        >
          <NavigateBefore />
        </IconButton>
        <IconButton
          className={controlPanelItem}
          disabled={items.length < itemPerPage || loading || !hasOtherPage}
          onClick={nextPage}
        >
          <NavigateNext />
        </IconButton>
      </div>
    </>
  );
};

export default RankingTable;
