import React, { useContext } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import AuthContext from './context/auth';
import Login from './screens/Login';
import Home from './screens/Home';
import Ranking from './screens/Ranking';
import Settings from './screens/Settings';
import Review from './screens/Review';
import Personality from './screens/Personality';

const App = ({ location }) => {
  const { state } = useContext(AuthContext);
  if (state.loading) return <div>Loading ...</div>;
  if (state.isAdmin && location.pathname === '/login')
    return <Redirect to="/" />;
  if (!state.isAdmin && location.pathname !== '/login')
    return <Redirect to="/login" />;

  return (
    <Switch>
      <Route path="/" component={Home} exact />
      <Route path="/login" component={Login} exact />
      <Route path="/ranking" component={Ranking} exact />
      <Route path="/settings" component={Settings} exact />
      <Route path="/review" component={Review} exact />
      <Route path="/personality" component={Personality} exact />
      review
    </Switch>
  );
};

export default withRouter(App);
