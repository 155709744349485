import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { Button, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import XLSX from 'xlsx';
import classNames from 'classnames';

import Layout from '../components/Layout';

import CloudService from '../services/Cloud';

const useStyles = makeStyles(theme => ({
  paper: theme.paper,
  row: theme.row,
  header: {
    justifyContent: 'space-between'
  },
  actionBar: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  exportButton: {
    margin: `${theme.spacing(1)}px 0`
  }
}));

const columns = [
  {
    Header: 'ลำดับที่',
    accessor: 'index'
  },
  {
    Header: 'ชื่อ',
    accessor: 'name'
  },
  {
    Header: 'วันที่ทำความสนใจ',
    accessor: 'Test1Date'
  },
  {
    Header: 'คะแนนความสนใจ',
    accessor: 'Test1Score'
  },
  {
    Header: 'บุคลิกภาพความสนใจ',
    accessor: 'Test1Type'
  },
  {
    Header: 'วันที่ทำความถนัด',
    accessor: 'Test2Date'
  },
  {
    Header: 'คะแนนความถนัด',
    accessor: 'Test2Score'
  },
  {
    Header: 'บุคลิกภาพความถนัด',
    accessor: 'Test2Type'
  }
];

const formatDate = date =>
  moment(date)
    .local()
    .format('DD-MM-YYYY HH:mm');

const Personality = () => {
  const [items, setItems] = useState([]);
  const { actionBar, exportButton, paper, row, header } = useStyles();

  useEffect(() => {
    CloudService.fetch('personalityResults').then(items =>
      setItems(
        items.map((item, index) => {
          const test1 = item.test1
            ? {
                Test1Date: formatDate(item.test1.createdAt.toDate()),
                Test1Score: item.test1.score,
                Test1Type: item.test1.type
              }
            : {};
          const test2 = item.test2
            ? {
                Test2Date: formatDate(item.test2.createdAt.toDate()),
                Test2Score: item.test2.score,
                Test2Type: item.test2.type
              }
            : {};
          return {
            index: index + 1,
            name: item.displayName,
            ...test1,
            ...test2
          };
        })
      )
    );
  }, []);

  const handleExport = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(items);
    XLSX.utils.book_append_sheet(wb, ws, 'personalityResults');
    XLSX.writeFile(wb, 'personalityResults.xlsx');
  };

  return (
    <Layout>
      <Paper className={paper}>
        <div className={classNames(row, header)}>
          <Typography variant="h4">Personality Results</Typography>
        </div>
        <ReactTable
          data={items}
          columns={columns}
          style={{ textAlign: 'center' }}
        />
        <div className={actionBar}>
          <Button
            variant="contained"
            color="primary"
            className={exportButton}
            onClick={() => handleExport()}
          >
            Export
          </Button>
        </div>
      </Paper>
    </Layout>
  );
};

export default Personality;
