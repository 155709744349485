import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Layout from '../components/Layout';

const useStyles = makeStyles(theme => ({
  paper: theme.paper,
  row: theme.row
}));
const Home = () => {
  const { paper, row } = useStyles();

  return (
    <Layout>
      <Paper className={paper}>
        <div className={row}>
          <Typography variant="h4">Dashboard</Typography>
        </div>
      </Paper>
    </Layout>
  );
};

export default Home;
