import React, { useState } from 'react';
import {
  Button,
  CircularProgress,
  Divider,
  Paper,
  Snackbar,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  config: {
    padding: '8px'
  },
  divider: {
    margin: '8px 0px'
  },
  paper: theme.paper,
  fileLabel: {
    marginLeft: theme.spacing(2)
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  row: theme.row,
  uploadButton: {
    marginRight: theme.spacing(1)
  }
}));

const Upload = props => {
  const [file, setFile] = useState(null);
  const {
    config,
    divider,
    fileLabel,
    paper,
    progress,
    row,
    uploadButton
  } = useStyles();

  return (
    <Paper className={paper}>
      <div className={row}>
        <Typography variant="h4">{props.topic}</Typography>
      </div>
      <div className={row}>
        <Button variant="contained" component="label">
          Browse
          <input
            type="file"
            style={{ display: 'none' }}
            onChange={e => setFile(e.target.files[0])}
          />
        </Button>
        <Typography className={fileLabel} inline>
          {file && file.name}
        </Typography>
      </div>
      <div className={row}>
        <Button
          className={uploadButton}
          variant="contained"
          color="primary"
          onClick={() => props.handleUpload(props.game, file)}
          disabled={file === null || props.loading}
        >
          Upload
          {props.loading && <CircularProgress size={24} className={progress} />}
        </Button>
        {props.devBtn ? (
          <Button
            className={uploadButton}
            variant="contained"
            color="secondary"
            onClick={() => props.handleDevUpload(props.game, file)}
            disabled={file === null || props.loading}
          >
            Dev Upload
            {props.loading && (
              <CircularProgress size={24} className={progress} />
            )}
          </Button>
        ) : null}
      </div>
      {props.configs && props.configs.length > 0 ? (
        <>
          <Divider className={divider} />
          <div className={config}>
            {props.configs.map((t, index) => (
              <Typography key={index}>{t}</Typography>
            ))}
          </div>
        </>
      ) : null}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={props.snackbar}
        autoHideDuration={3000}
        onClose={() => props.setSnackBar(false)}
        message={<span>{props.message}</span>}
      />
    </Paper>
  );
};

export default Upload;
